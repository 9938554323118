<template>
  <div class="devlog">
    <div class="container">
      <h1>Devlog 💬</h1>
      <h2>The plan as it unfolds</h2>
      <div class="posts">
        <post v-for="(post, index) in posts" :key="index"
          :title="post.title"
          :image="post.image"
          :imagealign="post.imagealign"
          :date="post.date"
          :text="post.text"
          :paragraphs="post.paragraphs"
        />
      </div>
    </div>
  </div>
</template>

<script>

import post from './../components/devlogpost';

export default {
  name: 'sls-devlog',
  components: { post },
  /* tslint:disable:max-line-length */
  data() {
    return {
      posts: [
        {
          title: 'Introducing, Curated Mods ⚙️',
          image: 'mods',
          imagealign: 'middle',
          date: '26th Jun 2020',
          paragraphs: [
            { text: `Hello there, Spire Spy-ers!`},
            { text: `As the observant of you may have already noticed, we have added a mods section to the website. Here you can find a curated set of mods that has been played by Maybe Later Games and earned our seal of approval.`},
            { text: `Why did we do this?`},
            { text: `A lot of people, myself included, might have been unaware of the enormous modding community that exists for Slay the Spire. Others might be worried about installing mods and some may just not know how. So I took it on myself to share with you some of the excellent mods that I've been playing recently!`},
            { text: `Interested?`},
            { text: `<a href="http://maybelatergames.co.uk/tools/slaythespire/mods"><u>Check out the mods page</u></a>.`},
            { text: `To find the download link for any of the mods, simply click on the review panel.`},
            { text: `Each mod review focuses on three specific areas: <b>difficulty</b>, <b>visuals</b>, and <b>fun</b>.`},
            { text: `<b>Visuals</b> and fun are the easiest to measure and have been given a rating out of five stars. Visuals can include character models, card art, and even custom sounds or particles that come with the mod. `},
            { text: `<b>Fun</b> is obviously very subjective but generally refers to how interesting and varied I think the mechanics are. `},
            { text: `Finally, <b>difficulty</b> is the measure of how hard or easy the game is in comparison to the base characters. 3 red dots is an extremely difficult character mod, and one green dot is a slightly easier mod than the default playing experience.`},
            { text: `<h2>Not sure how to play with mods?</h2>`},
            { text: `It’s simple!`},
            { text: `Subscribe to the following 3 prerequisites: <a href="https://steamcommunity.com/workshop/filedetails/?id=1605060445"><u>Mod the Spire</u></a>, <a href="https://steamcommunity.com/workshop/filedetails/?id=1605833019"><u>BaseMod</u></a> and <a href="https://steamcommunity.com/workshop/filedetails/?id=1609158507"><u>StSLib</u></a>`},
            { text: `Then in your Steam library, go to Slay the Spire, press play, and choose play with mods. Enable the three mods above and any combination of additional mods on top! (Note that playing with multiple mods simultaneously can cause issues sometimes, but most developers work on making their mods compatible with others.`},
            { text: `Finally... enjoy!`},
            { text: `Once again, thank you for all your support!`},
            { text: `As always, if you have any suggestions or feedback just hola! (By that, we mean send us a comment on the form at the bottom of the page. Or join our <a href="https://discord.gg/GSFJndG"><u>discord</u></a> for a chat!)`},
          ],
        },
        {
          title: 'What We Think 🤔',
          image: 'whatwethink',
          imagealign: 'middle',
          date: '25th Apr 2020',
          paragraphs: [
            { text: `Hello again Spire Spy-ers`},
            { text: `Firstly, a huge thank you for all the run uploads! We have now surpassed 20,000 run uploads 🎉`},
            { text: `Recently we've been thinking alot.`},
            { text: `In the last 4 weeks we've been looking at the feedback we receive relating to card and relic synergies and antisynergies. It appears that there is often confusion (and/or disagreement) around the tier rating of a card or relic so we've been looking at ways to try to explain some of that reasoning.`},
            { text: `To try to solve this we have now added a 'What we think' section to all cards and relics!`},
            { text: `Hopefully with this information you can make better judgements with your deck and you have more of an idea how we work out our synergies and antisynergies`},
            { text: `If you don't see a 'What we think' on all the items do not fret, we are still writing them 😵. In time they will all have one.`},
            { text: `While we were at it we decided to update the detail page for cards and relics to make things feel a little more concise, organised and neater. Cards now only display synergies and antisynergies of the same character, but if you want to see all characters you can expand the section (where those synergies exist).`},
            { text: `We have some more features in the pipeline, with a focus on the run data so you can better explore your runs.`},
            { text: `Once again, thank you for all your support!`},
            { text: `PS: Please keep uploading your runs 😉`},
          ],
        },
        {
          title: 'Evaluate 📉',
          image: 'evaluate',
          imagealign: 'middle',
          date: '16th Mar 2020',
          paragraphs: [
            { text: `Hi there Spire Spy-ers!`},
            { text: `First of all, thank you so much to everybody who has uploaded their games to the website so far. We now have a whopping 17,000 individual runs! Give yourselves a pat on the back. 😉 We hope that by having raw run data we can better analyse which cards are strongest and under which circumstances. If you haven’t uploaded your runs yet, get to it!`},
            { text: `Every game counts.`},
            { text: `With all this data at our fingertips it’s easy to get overwhelmed – rest assured, we’re going to be using the data sweetly and succinctly to avoid bogging you down with hundreds of details you never asked for. Our current plans are as follows:`},
            { text: `First up is a scatter graph of the floor each card was picked on, separated out by ascension level, and whether these runs resulted in a win or a loss. If you see lots of red dots in the early floors, this is most likely a card you want to pick up in Act 3. The dots higher up on the graph show how games pan out in higher ascension - a strategy that works on ascension 0 doesn’t necessarily work on ascension 20!`},
            { text: `We plan on showing both the Win Rate and Pick Rate for each card directly on the tier list screen. This might help you assess how popular and how successful certain cards are. A high win rate and high pick rate is a solid pick in any deck; a high win rate and low pick rate likely means that this card snuck into a niche deck where it showed its strengths, but it may be a card that has tricky win conditions (such as Signature Move or Meteor). Alongside our hand-picked tier listings, these will help you to navigate the spire and piece together game-breaking combos!`},
            { text: `As more graphs come to mind, we’ll be adding them to the card pages – showing upgrade rate, upgrade floor, and the optimal number of card copies (just to name a few) are all on our list for consideration.`},
            { text: `Thanks for all your support so far! We hope you are enjoying the website and it’s helping you smash through the spire one ascension at a time. If you have any suggestions or spot any bugs, let us know in the footer below and we’ll get on it right away. 💪`},
          ],
        },
        {
          title: 'Give Me Your Runs! ⚔️',
          image: 'runData',
          imagealign: 'bottom',
          date: '14th Jan 2020',
          paragraphs: [
            { text: `Firstly, Happy New Year!`},
            { text: `To bring the new year in we are really excited to announce that SpireSpy is now looking to start collecting run data!`},
            { text: `We aim to start adding extra stats to our card and relic data to help you navigate the ascensions, mixing curated and calculated information together in one place.`},
            { text: `This feature is currently in the beta stages of development but we think most of the infrastructure is in place to properly collect run data, fingers crossed.`},
            { text: `If you fancy uploading your runs just head to your game 'runs' folder then drag and drop any combination of files or folders onto the website page, the uploader works on <b>any page</b> including this one! Simply drag and drop your <b>whole 'runs' folder</b> and upload them all in a few seconds. 😵`},
            { text: `Your run files can be located by doing the following:<br/><blockquote>In your Steam library, right click on "Slay the Spire" and navigate to Properties > Local Files > Browse Local Files.</blockquote>`},
            { text: `We literally can't wait to start digging through run data for interesting trends. `},
            { text: `PS: We have some intersting new features we are really excited to build and launch in the coming months relating to run submissions. Keep an eye out. 👀`},
          ],
        },
        {
          title: 'The Watcher',
          image: 'watcherPortrait',
          imagealign: 'top',
          date: '13 Dec 2019',
          paragraphs: [
            { text: `We have just reviewed all the watcher cards to update them all before the official arrival of The Watcher!`},
            { text: `Thanks so much for the feedback given to us on cards from the community! As always if you have suggestions or feedback then do get in contact with us.`},
            { text: `Watch relics tierlist will be updated in the coming weeks.`},
          ],
        },
        {
          title: 'Astrolabe',
          image: 'cursedTome',
          imagealign: 'top',
          date: '06 May 2019',
          paragraphs: [
            { text: `Thanks so much for the feedback given to us on cards that have been changed or updated. These cards have now been updated in the database along with any consequences this might have for their synergies.`},
            { text: `We have been looking into server based features and are considering adding comments and ratings on specific synergies. This is something we have planned for the future. If you spot any other errors or have suggestions, don’t hesitate to get in touch using our feedback form at the bottom of the page!`},
          ],
        },
        {
          title: 'Back To Mobile Basics',
          image: 'backtobasics',
          imagealign: 'center',
          date: '14 Sep 2018',
          paragraphs: [
            { text: `Its been a while since our last update. So here goes!`},
            { text: `<h2>Mobile Ready</h2>`},
            { text: `We've been working diligently on improvements to the interface with a focus on mobile. The original intention was that users would be viewing the site on their desktop monitor while playing but tabbing in and out of game can get annoying for those not fortunate enough to have multiple monitors.`},
            { text: `With that in mind we've been working to streamline the interface so that its more usable on mobile and tablet devices to make it a tool you can use alongside your gameplay, WITHOUT the interuptions.`},
            { text: `There is still a way to go before the whole site is mobile ready, but we'll keep you updated as we work our way through it.`},
            { text: `We've also been looking at ways to improve rendering of the cards list and smoother scrolling. We hope the updates feel a little faster and the scrolling much smoother, but we are looking at ways to improve going forward as the number of cards and relics are ever growing!`},
            { text: `<ul>
                      <li>Navigation now mobile</li>
                      <li>Tierlist pages now mobile ready</li>
                      <li>Blog pages mobile ready</li>
                      <li>Improved card rendering and scrolling on long lists like the homepage</li>
            `},

            { text: `<h2>New Card Detail Overlays and Pages</h2>`},
            { text: `To help you find useful information about a single card we've created a new card detail interface. When clicking a card on the home page it will bring up details about the card, its synergies and antisynergies with both cards and relics.`},
            // { text: `We've also improved the card detail design fo`},
            { text: `<ul>
                      <li>New card detai overlay</li>
                      <li>Improved card detail design</li>
                      <li>Improved card detail page design for google referrals</li>
                      <li>Card detail overlay and pages mobile ready</li>
            `},
            { text: `<h2>New Cards</h2>`},
            { text: `There has been a raft of new cards and relics added which have now included in SpireSpy.`},
            { text: `<ul>
                      <li>New cards and relics added following on from the beta patches.</li>
                      <li>Card image updates for some of the newer colorless cards.</li>`},
            { text: `As always let us know if you like the imrpoved features and designs or if you have any feedback in general (form at the bottom of the page) . It helps us make this tool better for everyone!`},
          ],
        },
        {
          title: 'BIASED COGNITION',
          image: 'ballandcup',
          imagealign: 'bottom',
          date: '23 May 2018',
          paragraphs: [
            { text: `Tier lists are here and ready to go! Now you can see the general power level of a card when loading cards up in the comparator or when just generally browsing.`},
            { text: `The tier lists rank from S+ to F, and are a general indicator of how strong the card is IN ANY GIVEN DECK. There are a lot of cards that when combined have insanely strong combo potential, but alone are pretty terrible.`},
            { text: `The tier given is intended to show you how 'generally' strong each card is. That's not to say you can never pick an E rated card, but make sure you have the proper deck to support it before you do.`},
            { text: `This is particularly important in the early game, when there aren't as many synergies to look out for. It can often be a choice between 0 synergies, 0 synergies and 0 synergies, so this allows to give you some greater context to a card's strength - the higher tier cards are better stand-alone cards, and can help form a foundation before any clear synergies become apparent. It can even help out when EVERY card seems to have high synergy ratings - the defect class in particular has a lot of synergistic interactions involving evoking and channeling orbs, so it can help to give a little clarity when everything seems to fit into a deck.`},
            { text: `We've also added a handy <a style="text-decoration: underline;" href="/tools/slaythespire/tierlist/ironclad">tier lists page</a> for if you want to take a quick skim of the ratings before heading into the Spire.`},
          ],
        },
        {
          title: 'Sensory Stone Loadout Memory',
          image: 'sensoryStone',
          // imagealign: 'top',
          date: '7th April 2018',
          paragraphs: [
            { text: `Use of the sensory stone has allowed you to bring back the memory of the past (loadout you had built). Yes, your last loadout is now automatically saved!`},
            { text: `We've made it so that you can hop straight back in and carry on where you left off!`},
            { text: `Effortless domination awaits.`},
            { text: `To restore your last loadout just press the 'continue' button located in the navigation bar, if you can't see it, then you've not got a previous loadout.`},
            { text: `New Features:`},
            { text: `<ul><li>Restore previous loadout</li><li>Relics search bar added.</li></ul>`},
            { text: `Bug fixes:`},
            { text: `<ul><li>Fix an error on the comparator where it wasn't showing any card synergies only relic synergies.</li></ul>`},
          ],
        },
        {
          title: 'VINTAGE',
          image: 'goldenidol',
          imagealign: 'top',
          date: '6th April 2018',
          paragraphs: [
            { text: `It's here! Relics have been integrated into the deck builder and the comparer. Having trouble decided on a boss relic? Not anymore! Not sure what to get from the shop? Stick the cards and relics side by side to see all of their synergies at a glance!`},
            { text: `As always, if you encounter any bugs, get in contact with us using the report a bug feature. Every bug report counts!`},
            { text: `Coming soon: search bar improvements and ratio-based synergies!`},
          ],
        },
        {
          title: 'We\'re Live!',
          image: 'slshero',
          date: '24th March 2018',
          text: '<p> Hello and welcome to SpireSpy, a new tool for Slay The Spire that aims to help guide you around card and relic picks as you play. </p> <p> WLP is currently under heavy contruction and our aim is to keep you up-to-date with everything we are doing as we build more functionailty into the site. </p> <p> Currently WLP allows the following: </p> <ul> <li> <p>Browse all cards</p> </li> <li> <p>See card normal and upgraded states</p> </li> <li> <p>Build your game deck</p> </li> <li> <p>Analyse cards offered with cards in your deck to highligh synergies and antisynergies</p> </li> </ul> <p>This is a very early stage of the tool and there are other features we are working towards adding.</p> <p>Currently underway:</p> <ul> <li> <p>Relic comparing</p> </li> <li> <p>Relic synergies and antisynergies with cards</p> </li> <li> <p>Relic synergies and antisynergies with relics</p> </li> <li> <p>Basic card information</p> </li> </ul> <p> We are really excited to launch this product after working hard over the last 3 weeks to get it up and running. </p> <p>Well I think that just about wraps up our first devlog!</p> <p> PS: If you have any feedback we\'d love to hear it. Just post a message on the feedback form at the bottom of this page. </p> <p>Until next time!</p> <p>Ciao</p>',
        },
      ],
      metaTitle: 'Devlog | SpireSpy',
      metaDesc: 'Read up on the latest developer updates for SpireSpy, a Slay The Spire tier list and deck builder',
      metaImg: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png'),
      metaPageUrl: `https://maybelatergames.co.uk/${this.$route.fullPath}`,
    };
  },
  /* tslint:enable:max-line-length */
  head: {
    title() {
        return {
          inner: this.metaTitle,
        };
    },
    meta() {
      return [
        { name: 'description', content: this.metaDesc, id: 'desc' },

        // Google
        { itemprop: 'name', content: this.metaDesc },
        { itemprop: 'description', content: this.metaDesc },
        { itemprop: 'image', content: this.metaImg },

        // Facebook
        { property: 'og:title', content: this.metaDesc },
        { property: 'og:description', content: this.metaDesc },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Maybe Later Games' },
        { property: 'og:url', content: this.metaPageUrl },
        { property: 'og:image', content: this.metaImg },
        // { property: 'fb:app_id', content: '123456789' },
        // { property: 'og:locale', content: 'en_US' },
        // { property: 'article:author', content: '' },

        // Twitter
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.metaDesc },
        { name: 'twitter:description', content: this.metaDesc },
        { name: 'twitter:image', content: this.metaImg },

      ];
    },
    link: [
      { rel: 'canonical', href: 'https://maybelatergames.co.uk/spirespy/devlog/', id: 'canonical' },
    ]
  },

};
</script>

<style lang="scss" scoped>
.devlog {
  padding: 70px 40px;
  background: rgba(#10212B, 0.85);
}

.container {
  max-width: 740px;
  // margin: 0 40px;
  margin: auto;
}

h1 {
  font-size: 3.6em;
  text-align: center;
  // margin-bottom: 10px;
  font-weight: 500;
}

h2 {
  // font-size: 1.7em;
  text-align: center;
}

.posts {
  margin: 100px 0 0;
}
</style>
