<template>
  <article>
    <img
      :src="getImgUrl(imageUrl)"
      alt=""
      :style="{ objectPosition: imagealign }"
    />
    <div class="date">
      <div>
        <div></div>
      </div>
      <p>{{ this.date }}</p>
      <div>
        <div></div>
      </div>
    </div>
    <h1>{{ this.title }}</h1>
    <!-- <h2>Welcome to the first devlog</h2> -->
    <div class="text" v-html="this.text"></div>
    <p
      v-for="(paragraph, index) in paragraphs"
      :key="index"
      v-html="paragraph.text"
    >
      <!-- {{paragraph.text}} -->
    </p>
  </article>
</template>

<script>
export default {
  name: 'single-post',
  props: {
    title: {
      required: true,
      type: String,
    },
    date: {
      required: true,
      type: String,
    },
    image: {
      required: true,
      type: String,
    },
    imagealign: {
      default: 'center',
      type: String,
    },
    text: {
      // required: true,
      type: String,
    },
    paragraphs: {
      type: Array,
    },
  },
  computed: {
    imageUrl() {
      return `blog/posts/images/${this.image}`;
    },
    // mainPost(){
    //   this.paragraphs.forEach(paragraph => {
    //     let text = '<p>';
    //     text += paragraph;
    //     text += '</p>';
    //   });
    // }
  },
  methods: {
    getImgUrl(url) {
      var images = require.context('../assets/', true, /\.jpg$/);
      return images('./' + url + '.jpg');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 3.6em;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}
article {
  margin: 0 0 180px;
  text-align: left;

  img {
    max-height: 360px;
    object-fit: cover;
    width: 100%;
    // object-position: center;
  }

  p {
    font-size: 21px;
    line-height: 30px;
    margin: 32px 0;
  }
  .ul {
    list-style-type: circle;
    li {
      margin-left: 20px;
    }
  }

  h1 {
    margin: 16px 0 30px;
    text-align: center;
    font-size: 2.7em;
  }

  .date {
    display: flex;
    align-items: center;
    margin: -50px 0 -20px;
    > p {
      font-size: 14px;
      // font-weight: 600;
      text-transform: uppercase;
      padding: 0 14px;
      background: #2a3e44;
      border: 2px solid #c9bf92;
      border-radius: 8px;
      box-shadow: rgba(#000000, 0.5) 0 2px 3px 1px;
    }
    > div {
      // margin: 0 10px;
      position: relative;

      > div {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          height: 2px;
          box-shadow: rgba(#000000, 0.5) 0 3px 0 0;
          background: #c9bf92;
          opacity: 1;
          // z-index: -1;
        }
      }
      flex-grow: 1;
    }
  }
  .text {
    font-size: 21px;
    line-height: 30px;
    margin: 32px 0;
    p {
      color: #000;
    }
  }
}
</style>
